import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/blog-post-layout.js";
import { Link } from 'gatsby';
import Date from '../../components/Post/date';
import Alert from '../../components/Alert/alert';
import HeroPost from '../../components/Hero/hero-post';
import ColorBadge from '../../components/ColorBadge/color-badge';
import TwitterBox from '../../components/TwitterBox/twitter-box';
import SortingColors from '../../components/PostExtras/SortingColors/sorting-colors';
import SortingColorsWithSelect from '../../components/PostExtras/SortingColorsWithSelect/sorting-colors-with-select';
import PostsNavigation from '../../components/Post/posts-navigation';
import styles from './010-sorting-colors-in-js.module.css';
import heroImg from '../../images/posts/010/sorting-colors-in-js.svg';
import colorAffectedByBg from '../../images/posts/010/color-affected-by-background.svg';
import clusterColors from '../../images/posts/010/cluster-colors.svg';
import rgbColorCube from '../../images/posts/010/RGB_color_cube.svg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <HeroPost gradientFrom="#333" gradientTo="#3B3B3B" hasTiles={false} mdxType="HeroPost">
  <img src={heroImg} alt="Sorting colors in JavaScript" height="238" width="604" />
    </HeroPost>
    <h1>{`Sorting colors in JavaScript`}</h1>
    <Date date="June 22, 2021" dateMachine="2021-06-22" mdxType="Date" />
    <p><strong parentName="p">{`How to sort colors in JavaScript?`}</strong>{` Let me tell you a story first. In the project I'm working on right now we used
to have 134 colors in use! WTF?! you say.`}</p>
    <p>{`Once I discovered that I thought I'm going to show that to my colleagues, and we will address the problem.
`}<em parentName="p">{`Unfortunately`}</em>{`, I'm a very visual person (so to say) and I couldn't stand the very random order of the colors listed:`}</p>
    <SortingColors mdxType="SortingColors" />
    <p>{`This type of personality helps with paying attention to details but is problematic with stuff like this 😅 so...`}</p>
    <h2>{`Let's just sort them`}</h2>
    <p>{`My first approach to fix the problem was to just run regular `}<inlineCode parentName="p">{`.sort()`}</inlineCode>{` on array and hope for the best. This is the result:`}</p>
    <SortingColors sortingAlgo="basic" mdxType="SortingColors" />
    <p>{`Not great, not terrible, right? Unfortunately, we can see some outliers here. Like reds are placed completely random.`}</p>
    <p>{`Okay, I think I forgot to say that we don't only use HEX values in our codebase but also `}<inlineCode parentName="p">{`rgba`}</inlineCode>{`.
That should explain why HEX values like #999 `}<ColorBadge color="#999" mdxType="ColorBadge" />{` are placed quite far away from
rgba(153, 153, 153, 1) `}<ColorBadge color="rgb(153, 153, 153, 1)" mdxType="ColorBadge" />{` even though it's the same grey color and should
be placed next to each other.`}</p>
    <h2>{`Keep colors in the same format`}</h2>
    <p>{`There are a couple of formats to hold a color but I know that in my set I have HEX and rgba. So I'm going to convert
all hexes into rgba with `}<inlineCode parentName="p">{`alpha=1`}</inlineCode>{` and pad `}<inlineCode parentName="p">{`r,g,b`}</inlineCode>{` with zeros. This way, simple `}<inlineCode parentName="p">{`.sort()`}</inlineCode>{` will still do the job.
Because by default `}<strong parentName="p">{`it sorts stuff alphabetically`}</strong>{`. 004,255,255 should be listed before 086,001,001 and after 001,124,088
and so on.`}</p>
    <p>{`Let's see the result:`}</p>
    <SortingColors sortingAlgo="basic-same-format" mdxType="SortingColors" />
    <p><strong parentName="p">{`It looks like a step backward.`}</strong>{` The first 7 colors are basically black `}<ColorBadge color="#000" mdxType="ColorBadge" />{` but with different alpha channel values. Depending on
the background they will look different. Most of them are more grey than black when I look at them.`}</p>
    <p>{`Wrong approach I think.`}</p>
    <h2>{`Opacity is problematic`}</h2>
    <p>{`So we've just learned that having colors with alpha channel (opacity) is problematic. Depending on the background color they
look different:`}</p>
    <div className="container">
  <div className={styles.image}>
    <img src={colorAffectedByBg} alt="Color with opacity is affected by background" height="250" width="589" />
  </div>
    </div>
    <p>{`On the picture above we have the same green circle with opacity `}<ColorBadge color="rgba(0,255,0,.5)" mdxType="ColorBadge" />{` on the lefts
but depending on the background (`}<ColorBadge color="#0085FF" mdxType="ColorBadge" />{` vs `}<ColorBadge color="#FFF" hasBorder={true} mdxType="ColorBadge" />{`) it's still
that color (on the right) or it's a different color now `}<ColorBadge color="#06C29F" mdxType="ColorBadge" />{` on the left.`}</p>
    <p><strong parentName="p">{`To properly sort colors with opacity`}</strong>{` we will assume the background is white `}<ColorBadge color="#FFF" hasBorder={true} mdxType="ColorBadge" />{`.
So we have to apply the background and transform them into new colors. (0, 0, 0, 0.5) which is black with 50% opacity `}<ColorBadge color="rgba(0,0,0,.5)" mdxType="ColorBadge" />{`
will be changed into (127, 127, 127, 1) `}<ColorBadge color="rgba(127,127,127,1)" mdxType="ColorBadge" />{`.`}</p>
    <p>{`This technique is called `}<strong parentName="p">{`blending`}</strong>{` and will help us in eliminating the alpha channel. What are the results then?`}</p>
    <SortingColors sortingAlgo="no-alpha" mdxType="SortingColors" />
    <p>{`Not satisfying I'd say. I mean, I see the progress but still, we have outliers here. Some colors started to appear in groups which is good
but `}<strong parentName="p">{`using simple`}</strong>{` `}<inlineCode parentName="p">{`sort()`}</inlineCode>{` `}<strong parentName="p">{`is naïve`}</strong>{` I think.`}</p>
    <Alert mdxType="Alert">
  Blending colors is a topic on its own. I'm using a simplified blending
  technique that may not show exactly the same color as a browser would do. But
  it's going to be good enough for sorting.
    </Alert>
    <h2>{`Maybe treat the value as a number?`}</h2>
    <p>{`It seems that simple `}<inlineCode parentName="p">{`sort()`}</inlineCode>{` that sorts strings alphabetically is not the right choice then. So what about treating HEXes as
numbers? Let's say every HEX can be represented by a number from 0 (#000000) to 765 (#FFFFFF). Do you see what I did?
It's just a sum of HEX parts for r, g, b.`}</p>
    <p>{`Does it make sense? Look below:`}</p>
    <SortingColors sortingAlgo="no-alpha-int" mdxType="SortingColors" />
    <p>{`Some parts look better and some worse. Definitely, the `}<strong parentName="p">{`darker colors appear on the top`}</strong>{` and lighter colors appear
on the bottom.`}</p>
    <p>{`But the colors themselves are not grouped. Blues are not together, neither reds nor greens.`}</p>
    <h2>{`Back to square one`}</h2>
    <p>{`So far I had no luck in sorting these colors so I decided to go back to square one and ask myself what I want to accomplish.
`}<strong parentName="p">{`It seems that I jumped into solutions-mode too quickly.`}</strong>{` So how do I want to sort these colors?`}</p>
    <ul>
      <li parentName="ul">{`they should be grouped like greens, reds, blues, yellows, etc. together,`}</li>
      <li parentName="ul">{`within these groups, they should be sorted from lighter to darker.`}</li>
    </ul>
    <p>{`But really? Is the second bullet correct? Should very light blue `}<ColorBadge color="rgb(229, 245, 251)" hasBorder={true} mdxType="ColorBadge" />{` be listed next to
greys like `}<ColorBadge color="rgba(230, 230, 230, 0.5)" hasBorder={true} mdxType="ColorBadge" />{` or next to "solid" blues
like `}<ColorBadge color="rgb(17, 137, 202)" mdxType="ColorBadge" />{`? 🤔 What a group even means?`}</p>
    <p>{`That got me thinking 💡. `}<strong parentName="p">{`I was trying to solve a multi-dimensional problem in one dimension.`}</strong>{` Despite you see my set of colors
as a rectangle it is in fact a "linear" array with colors floated next to each other.`}</p>
    <p>{`RGB itself has 3 dimensions - red, green, blue but I never thought of this that way. Even more obvious with the `}<inlineCode parentName="p">{`HSL`}</inlineCode>{` format
where we operate on hue, saturation, and lightness.`}</p>
    <p>{`So far I was able to sort colors by lightness without even knowing. Happy accident I'd say. The problem in general
is highly subjective as you can see. I'm looking for the order `}<strong parentName="p">{`that would please my human eye`}</strong>{`. From a machine point of view,
it was all sorted properly in every attempt.`}</p>
    <h2>{`One dimensional sort`}</h2>
    <p>{`You don't have to trust my words but just play with the select below and choose a different sorting method. Each of them
sorts colors just by one dimension - hue, saturation, or lightness.`}</p>
    <SortingColorsWithSelect mdxType="SortingColorsWithSelect" />
    <p>{`Doesn't look like sorted, does it? At least for me, sorting by hue and saturation is not intuitive. Lightness looks pretty good
comparing to them... but that's something we already accomplished and I'm not fully satisfied with the result.`}</p>
    <h2>{`The solution`}</h2>
    <p>{`To properly sort colors `}<strong parentName="p">{`we need to add another dimension`}</strong>{`. If we only sort by hue or saturation or lightness then it's not
going to please our human eyes. There is a reason why color palettes are often shown in two-dimensional charts. Three dimensions are
even more accurate but we just not got used to them.`}</p>
    <p>{`I still want to show my colors in a linear matter so I'll introduce the 2nd dimension by forming clusters. `}<strong parentName="p">{`Clusters of colors`}</strong>{`. It's
like grouping. Then I'm going to calculate the distance between colors and move the color to the closest cluster.`}</p>
    <p>{`I have chosen primary + secondary + tertiary colors as the leading colors of each cluster. Additionally, I decided to help
to form groups by introducing black, white, and a "grey" color:`}</p>
    <div className="container">
  <div className={styles.image}>
    <img src={clusterColors} alt="Leading colors for each cluster" height="485" width="740" />
  </div>
    </div>
    <p>{`Measuring distance itself is a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Euclidean_distance"
      }}>{`mathematical operation on vectors`}</a>{`
(Wikipedia is your friend). Keeping in mind we have 3 dimensions, we can place a point in 3D space like
(x,y,z) = (255,95,87) `}<ColorBadge color="rgb(255,95,87)" mdxType="ColorBadge" />{`. Then we just need to calculate if it's closer
to orange `}<ColorBadge color="rgb(255,128,0)" mdxType="ColorBadge" />{` or green `}<ColorBadge color="rgb(0,255,0)" mdxType="ColorBadge" />{` for example.`}</p>
    <p><strong parentName="p">{`But there is something important that will affect my sorting.`}</strong>{` The closer to black, grey, white color `}<em parentName="p">{`a`}{` `}{`color`}</em>{` is the harder
it is to spot "the color itself". I mean, the distance to the grey (or black, or white) will be shorter than to something we
would call "a color". The easiest way to understand it is by looking at the cube below:`}</p>
    <div className="container">
  <div className={styles.image}>
    <img src={rgbColorCube} alt="RGB color cube" height="245" width="873" />
  </div>
    </div>
    <p>{`A huge black-to-grey-to-white monster lives inside.`}</p>
    <h2>{`The result`}</h2>
    <p>{`Enough talking, let's see the result. First look at clusters formed. Then uncheck `}<em parentName="p">{`"Show clusters"`}</em>{` to see how it all looks together.
I filtered out clusters that don't hold any color:`}</p>
    <SortingColors sortingAlgo="clusters" mdxType="SortingColors" />
    <p><strong parentName="p">{`Am I happy with the result?`}</strong>{` Nah, `}<strong parentName="p">{`not 100%`}</strong>{`. I still see some outliers. `}<strong parentName="p">{`But I call it good enough`}</strong>{`. Especially when I show it in clusters.`}</p>
    <p>{`Now I can reveal that this list was our input to start thinking about a palette of colors for our styleguide... and for that reason,
this grouping (sorting) worked perfectly.`}</p>
    <p>{`If time lets me do so I'll revisit this problem and find an even better solution. Remember I mentioned the black-to-grey-to-white
monster living in the RGB cube? `}<strong parentName="p">{`He holds the answers 🔑`}</strong>{`.`}</p>
    <p>{`For example, I see this blue color `}<ColorBadge color="rgb(124, 190, 237)" mdxType="ColorBadge" />{` is grouped under grey `}<ColorBadge color="rgb(235, 235, 235)" mdxType="ColorBadge" />{`.
The distance to grey is equal to ~120 units where the closest "blue" (azure `}<ColorBadge color="rgb(0, 127, 255)" mdxType="ColorBadge" />{`) is ~140 units away.
There must be some non-linear connotation there for us humans.`}</p>
    <h2>{`Human perception!`}</h2>
    <p>{`If you were wondering if we are machines, then it looks like we are not. At least the algorithms in our brains are more
complex than it's needed. That's not elegant Mr. God!`}</p>
    <p>{`The monster I was talking about is `}<strong parentName="p">{`the way we perceive colors`}</strong>{`. For many years there were many attempts to handle that. What's
most important for us here is that RGB space is non-uniform. This means the Euclidian distance between points does not represent
what we, humans, perceive. As we have seen in the calculation example above.`}</p>
    <p>{`Algorithms that claim to be better at measuring the distance are often represented in CIELAB ΔE`}{`*`}{` space which was designed to be uniform.
This means if you go in any direction then the way we perceive the change is linear. `}<strong parentName="p">{`That was proven to be wrong.`}</strong>{` Different
formulas (CIE76, CIE94, CIEDE2000) were proposed through the years to be more accurate and compensate for some non-linear areas in this space.`}</p>
    <p>{`I'll write about it the other day and update this post. As I said, `}<strong parentName="p">{`good enough for now`}</strong>{`.`}</p>
    <h2>{`The code`}</h2>
    <p>{`First of all, do yourself a favor and use a library to convert colors from all formats to all formats ;) There is no reason
to write it by hand. I picked `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/color-util"
      }}>{`color-util`}</a>{`.`}</p>
    <h3>{`Blending`}</h3>
    <p>{`In case you have `}<inlineCode parentName="p">{`rgba`}</inlineCode>{` colors use this function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "{showLines}",
        "{showLines}": true
      }}>{`function blendRgbaWithWhite(rgba) {
  const color = colorUtil.color(rgba);
  const a = color.rgb.a / 256;
  const r = Math.floor(color.rgb.r * a + 0xff * (1 - a));
  const g = Math.floor(color.rgb.g * a + 0xff * (1 - a));
  const b = Math.floor(color.rgb.b * a + 0xff * (1 - a));

  return '#' + ((r << 16) | (g << 8) | b).toString(16);
}
`}</code></pre>
    <p>{`We parse the rgba, normalize the alpha value (line `}<span className="line-number">{`3`}</span>{`) and then make a mathematical transformation
on every channel. We will keep the result as hex.`}</p>
    <h3>{`Preparing clusters`}</h3>
    <p>{`You can add as many clusters (groups) as you want but for me, these colors were just fine:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "{2}",
        "{2}": true
      }}>{`const clusters = [
  { name: 'red', leadColor: [255, 0, 0], colors: [] },
  { name: 'orange', leadColor: [255, 128, 0], colors: [] },
  { name: 'yellow', leadColor: [255, 255, 0], colors: [] },

  { name: 'chartreuse', leadColor: [128, 255, 0], colors: [] },
  { name: 'green', leadColor: [0, 255, 0], colors: [] },
  { name: 'spring green', leadColor: [0, 255, 128], colors: [] },

  { name: 'cyan', leadColor: [0, 255, 255], colors: [] },
  { name: 'azure', leadColor: [0, 127, 255], colors: [] },
  { name: 'blue', leadColor: [0, 0, 255], colors: [] },

  { name: 'violet', leadColor: [127, 0, 255], colors: [] },
  { name: 'magenta', leadColor: [255, 0, 255], colors: [] },
  { name: 'rose', leadColor: [255, 0, 128], colors: [] },

  { name: 'black', leadColor: [0, 0, 0], colors: [] },
  { name: 'grey', leadColor: [235, 235, 235], colors: [] },
  { name: 'white', leadColor: [255, 255, 255], colors: [] },
];
`}</code></pre>
    <p>{`Each of them has a leading color in RGB-array format and `}<inlineCode parentName="p">{`colors`}</inlineCode>{` array waiting to be filled.`}</p>
    <h3>{`Sorting`}</h3>
    <p>{`The sorting algorithm looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "{showLines},{codepenUrl=https://codepen.io/tniezurawski/pen/zYZQRvG}",
        "{showLines},{codepenUrl": "https://codepen.io/tniezurawski/pen/zYZQRvG}"
      }}>{`function sortWithClusters(colorsToSort) {
  // const clusters = [...]; // as defined above

  const mappedColors = colorsToSort
    .map((color) => {
      const isRgba = color.includes('rgba');
      if (isRgba) {
        return blendRgbaWithWhite(color);
      } else {
        return color;
      }
    })
    .map(colorUtil.color);

  mappedColors.forEach((color) => {
    let minDistance;
    let minDistanceClusterIndex;

    clusters.forEach((cluster, clusterIndex) => {
      const colorRgbArr = [color.rgb.r, color.rgb.g, color.rgb.b];
      const distance = colorDistance(colorRgbArr, cluster.leadColor);

      if (typeof minDistance === 'undefined' || minDistance > distance) {
        minDistance = distance;
        minDistanceClusterIndex = clusterIndex;
      }
    });

    clusters[minDistanceClusterIndex].colors.push(color);
  });

  clusters.forEach((cluster) => {
    const dim = ['white', 'grey', 'black'].includes(cluster.name) ? 'l' : 's';
    cluster.colors = oneDimensionSorting(cluster.colors, dim)
  });

  return clusters;
}
`}</code></pre>
    <p>{`What happens here?`}</p>
    <ul>
      <li parentName="ul">{`between lines `}<span className="line-number">{`4-13`}</span>{` we normalize the input by blending rgba and using a util function to store colors`}</li>
      <li parentName="ul">{`then we iterate through colors and each cluster to find the closest one (lines `}<span className="line-number">{`21-26`}</span>{`),`}</li>
      <li parentName="ul">{`in the end, we push the color to a cluster (line `}<span className="line-number">{`29`}</span>{`)`}</li>
    </ul>
    <h3>{`The distance`}</h3>
    <p>{`In line `}<span className="line-number">{`21`}</span>{` I call a `}<inlineCode parentName="p">{`colorDistance`}</inlineCode>{` function. The true
power and accuracy of sorting are hidden in calculating the distance between colors. But as noted before, we go a simple
Euclidian way:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function colorDistance(color1, color2) {
  const x =
    Math.pow(color1[0] - color2[0], 2) +
    Math.pow(color1[1] - color2[1], 2) +
    Math.pow(color1[2] - color2[2], 2);
  return Math.sqrt(x);
}
`}</code></pre>
    <p>{`For computational reasons we could skip `}<inlineCode parentName="p">{`Math.sqrt`}</inlineCode>{` so sorting would work faster and the result would be exactly the same.
For teaching purposes, I'll leave it as it is.`}</p>
    <h3>{`One dimension sorting`}</h3>
    <p>{`To sort colors within groups I have a strategy. Black, grey, and white colors are sorted by lightness and other colors by saturation.
For me, it produced the best results. Here is the `}<inlineCode parentName="p">{`oneDimensionSorting`}</inlineCode>{` function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function oneDimensionSorting(colors, dim) {
  return colors
    .sort((colorA, colorB) => {
      if (colorA.hsl[dim] < colorB.hsl[dim]) {
        return -1;
      } else if (colorA.hsl[dim] > colorB.hsl[dim]) {
        return 1;
      } else {
        return 0;
      }
    });
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/tniezurawski/pen/zYZQRvG"
      }}>{`Check the full code on CodePen`}</a>{` to see how it all plays together.`}</p>
    <h2>{`What's next?`}</h2>
    <p>{`For my purposes, the sorting and grouping worked good but I can imagine you might want to be more accurate. The problem is non-trivial
as you see, and this post is already super long. `}<strong parentName="p">{`People make PhDs on how to measure the distance between colors`}</strong>{` and create
new spaces that would properly model human perception!`}</p>
    <p>{`Watch this space. Cheers!`}</p>
    <p>{`PS. If you like algorithms then check `}<Link to="/posts/search-with-typo-tolerance" mdxType="Link">{`Search with typo tolerance`}</Link>{`.`}</p>
    <div className="container mt-40 mb-55">
  <TwitterBox threadLink="https://twitter.com/tomekdev_/status/1407311518476144641" mdxType="TwitterBox" />
    </div>
    <PostsNavigation prevTitle="Anchors for headings in MDX" prevLink="/posts/anchors-for-headings-in-mdx" nextTitle="Interactive Radial Gauge" nextLink="/posts/interactive-radial-gauge-in-react" mdxType="PostsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      